.login-bg {
    background-image: url('./login-bg.png');
}

#showPasswordToggle {
    cursor: pointer;
    margin-left: 5px;
}


/* Align the checkbox with the password input */
.password-toggle {
    display: flex;
    align-items: center;
    gap: 6px;
    /* Adjust spacing between checkbox and label */
    margin-top: 6px;
    /* Add space above checkbox */
}

/* Ensure checkbox size is consistent */
.password-toggle .form-check-input {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

/* Improve label styling */
.password-toggle .form-check-label {
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    padding-top: 5px;
}